@import 'https://code.highcharts.com/css/stocktools/gui.css';
@import 'https://code.highcharts.com/css/annotations/popup.css';
@import './MUIstyling.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  textarea:focus {
    border-color: inherit;
    box-shadow: none;
    outline: none;
  }
}

:root {
  --black: rgba(0, 0, 0, 1);
  --body-bold-font-family: 'Inter-Bold', Helvetica;
  --body-bold-font-size: 18px;
  --body-bold-font-style: normal;
  --body-bold-font-weight: 700;
  --body-bold-letter-spacing: -0.07740000128746033px;
  --body-bold-line-height: 24px;
  --body-h4-bold-font-family: 'Inter-Bold', Helvetica;
  --body-h4-bold-font-size: 20px;
  --body-h4-bold-font-style: normal;
  --body-h4-bold-font-weight: 700;
  --body-h4-bold-letter-spacing: -0.0899999976158142px;
  --body-h4-bold-line-height: 25px;
  --body-h4-medium-font-family: 'Inter-Medium', Helvetica;
  --body-h4-medium-font-size: 20px;
  --body-h4-medium-font-style: normal;
  --body-h4-medium-font-weight: 500;
  --body-h4-medium-letter-spacing: -0.0899999976158142px;
  --body-h4-medium-line-height: 25px;
  --body-h4-regular-font-family: 'Inter-Regular', Helvetica;
  --body-h4-regular-font-size: 20px;
  --body-h4-regular-font-style: normal;
  --body-h4-regular-font-weight: 400;
  --body-h4-regular-letter-spacing: -0.0899999976158142px;
  --body-h4-regular-line-height: 25px;
  --body-h4-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --body-h4-semibold-font-size: 20px;
  --body-h4-semibold-font-style: normal;
  --body-h4-semibold-font-weight: 600;
  --body-h4-semibold-letter-spacing: -0.0899999976158142px;
  --body-h4-semibold-line-height: 25px;
  --body-medium-font-family: 'Inter-Medium', Helvetica;
  --body-medium-font-size: 18px;
  --body-medium-font-style: normal;
  --body-medium-font-weight: 500;
  --body-medium-letter-spacing: -0.07740000128746033px;
  --body-medium-line-height: 24px;
  --body-regular-font-family: 'Inter-Regular', Helvetica;
  --body-regular-font-size: 18px;
  --body-regular-font-style: normal;
  --body-regular-font-weight: 400;
  --body-regular-letter-spacing: -0.07740000128746033px;
  --body-regular-line-height: 24px;
  --body-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --body-semibold-font-size: 18px;
  --body-semibold-font-style: normal;
  --body-semibold-font-weight: 600;
  --body-semibold-letter-spacing: -0.07740000128746033px;
  --body-semibold-line-height: 24px;
  --body-small-bold-font-family: 'Inter-Bold', Helvetica;
  --body-small-bold-font-size: 16px;
  --body-small-bold-font-style: normal;
  --body-small-bold-font-weight: 700;
  --body-small-bold-letter-spacing: -0.06880000114440918px;
  --body-small-bold-line-height: 22px;
  --body-small-light-font-family: 'Inter-Light', Helvetica;
  --body-small-light-font-size: 16px;
  --body-small-light-font-style: normal;
  --body-small-light-font-weight: 300;
  --body-small-light-letter-spacing: -0.06880000114440918px;
  --body-small-light-line-height: 22px;
  --body-small-medium-font-family: 'Inter-Medium', Helvetica;
  --body-small-medium-font-size: 16px;
  --body-small-medium-font-style: normal;
  --body-small-medium-font-weight: 500;
  --body-small-medium-letter-spacing: -0.06880000114440918px;
  --body-small-medium-line-height: 22px;
  --body-small-regular-font-family: 'Inter-Regular', Helvetica;
  --body-small-regular-font-size: 16px;
  --body-small-regular-font-style: normal;
  --body-small-regular-font-weight: 400;
  --body-small-regular-letter-spacing: -0.06880000114440918px;
  --body-small-regular-line-height: 22px;
  --body-small-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --body-small-semibold-font-size: 16px;
  --body-small-semibold-font-style: normal;
  --body-small-semibold-font-weight: 600;
  --body-small-semibold-letter-spacing: -0.06880000114440918px;
  --body-small-semibold-line-height: 22px;
  --caption-1-bold-font-family: 'Inter-Bold', Helvetica;
  --caption-1-bold-font-size: 12px;
  --caption-1-bold-font-style: normal;
  --caption-1-bold-font-weight: 700;
  --caption-1-bold-letter-spacing: 0px;
  --caption-1-bold-line-height: 16px;
  --caption-1-medium-font-family: 'Inter-Medium', Helvetica;
  --caption-1-medium-font-size: 12px;
  --caption-1-medium-font-style: normal;
  --caption-1-medium-font-weight: 500;
  --caption-1-medium-letter-spacing: 0px;
  --caption-1-medium-line-height: 16px;
  --caption-1-regular-font-family: 'Inter-Regular', Helvetica;
  --caption-1-regular-font-size: 12px;
  --caption-1-regular-font-style: normal;
  --caption-1-regular-font-weight: 400;
  --caption-1-regular-letter-spacing: 0px;
  --caption-1-regular-line-height: 16px;
  --caption-1-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --caption-1-semibold-font-size: 12px;
  --caption-1-semibold-font-style: normal;
  --caption-1-semibold-font-weight: 600;
  --caption-1-semibold-letter-spacing: 0px;
  --caption-1-semibold-line-height: 16px;
  --caption-2-bold-font-family: 'Inter-Bold', Helvetica;
  --caption-2-bold-font-size: 10px;
  --caption-2-bold-font-style: normal;
  --caption-2-bold-font-weight: 700;
  --caption-2-bold-letter-spacing: 0.005999999865889549px;
  --caption-2-bold-line-height: 13px;
  --caption-2-medium-font-family: 'Inter-Medium', Helvetica;
  --caption-2-medium-font-size: 10px;
  --caption-2-medium-font-style: normal;
  --caption-2-medium-font-weight: 500;
  --caption-2-medium-letter-spacing: 0.005999999865889549px;
  --caption-2-medium-line-height: 13px;
  --caption-2-regular-font-family: 'Inter-Regular', Helvetica;
  --caption-2-regular-font-size: 10px;
  --caption-2-regular-font-style: normal;
  --caption-2-regular-font-weight: 400;
  --caption-2-regular-letter-spacing: 0.005999999865889549px;
  --caption-2-regular-line-height: 13px;
  --caption-2-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --caption-2-semibold-font-size: 10px;
  --caption-2-semibold-font-style: normal;
  --caption-2-semibold-font-weight: 600;
  --caption-2-semibold-letter-spacing: 0.005999999865889549px;
  --caption-2-semibold-line-height: 13px;
  --colorsbgdarkprimary: rgba(52, 53, 64, 1);
  --colorsbglightprimary: rgba(255, 255, 255, 1);
  --colorsdefaultsystembluedark: rgba(10, 132, 255, 1);
  --colorsdefaultsystembluelight: rgba(0, 122, 255, 1);
  --colorsdefaultsystemgreendark: rgba(50, 215, 75, 1);
  --colorsdefaultsystemgreenlight: rgba(52, 199, 89, 1);
  --colorsdefaultsystemindigodark: rgba(94, 92, 230, 1);
  --colorsdefaultsystemindigolight: rgba(88, 86, 214, 1);
  --colorsdefaultsystemorangedark: rgba(255, 159, 10, 1);
  --colorsdefaultsystemorangelight: rgba(255, 149, 0, 1);
  --colorsdefaultsystempinkdark: rgba(255, 45, 85, 1);
  --colorsdefaultsystempinklight: rgba(255, 45, 85, 1);
  --colorsdefaultsystempurpledark: rgba(191, 90, 242, 1);
  --colorsdefaultsystempurplelight: rgba(175, 82, 222, 1);
  --colorsdefaultsystemreddark: rgba(170, 46, 38, 1);
  --colorsdefaultsystemredlight: rgba(255, 59, 48, 1);
  --colorsdefaultsystemtealdark: rgba(100, 210, 255, 1);
  --colorsdefaultsystemteallight: rgba(90, 200, 250, 1);
  --colorsdefaultsystemyellowdark: rgba(255, 214, 10, 1);
  --colorsdefaultsystemyellowlight: rgba(255, 204, 0, 1);
  --colorsfilldarkprimary: rgba(0, 123, 255, 1);
  --colorsfilldarkquaternary: rgba(28, 28, 30, 1);
  --colorsfilldarksecondary: rgba(94, 238, 205, 1);
  --colorsfilldarktertiary: rgba(255, 255, 255, 1);
  --colorsfilllightprimary: rgba(0, 123, 255, 1);
  --colorsfilllightquaternary: rgba(255, 255, 255, 1);
  --colorsfilllightsecondary: rgba(94, 238, 205, 1);
  --colorsfilllighttertiary: rgba(28, 28, 30, 1);
  --colorsgraysdarkprimary: rgba(255, 255, 255, 1);
  --colorsgraysdarkquaternary: rgba(124, 124, 136, 1);
  --colorsgraysdarkquinary: rgba(68, 70, 84, 1);
  --colorsgraysdarksecondary: rgba(247, 247, 248, 1);
  --colorsgraysdarksenary: rgba(52, 53, 65, 1);
  --colorsgraysdarkseptenary: rgba(32, 33, 35, 1);
  --colorsgraysdarktertiary: rgba(197, 197, 209, 1);
  --colorsgrayslightprimary: rgba(32, 33, 35, 1);
  --colorsgrayslightquaternary: rgba(229, 229, 229, 1);
  --colorsgrayslightquinary: rgba(197, 197, 209, 1);
  --colorsgrayslightsecondary: rgba(52, 53, 65, 1);
  --colorsgrayslightsenary: rgba(247, 247, 248, 1);
  --colorsgrayslightseptenary: rgba(255, 255, 255, 1);
  --colorsgrayslighttertiary: rgba(68, 70, 84, 1);
  --colorsseparatordark: rgba(84, 84, 88, 0.65);
  --colorsseparatorlight: rgba(228, 229, 232, 1);
  --colorstextdarkprimary: rgba(255, 255, 255, 1);
  --colorstextdarkquaternary: rgba(124, 124, 136, 1);
  --colorstextdarkquinary: rgba(68, 70, 84, 1);
  --colorstextdarksecondary: rgba(0, 0, 0, 1);
  --colorstextdarksenary: rgba(52, 53, 65, 1);
  --colorstextdarkseptenary: rgba(32, 33, 35, 1);
  --colorstextdarktertiary: rgba(197, 197, 209, 1);
  --colorstextlightprimary: rgba(28, 28, 30, 1);
  --colorstextlightquaternary: rgba(163, 163, 163, 1);
  --colorstextlightquinary: rgba(229, 229, 229, 1);
  --colorstextlightsecondary: rgba(255, 255, 255, 1);
  --colorstextlightsenary: rgba(236, 236, 241, 1);
  --colorstextlightseptenary: rgba(255, 255, 255, 1);
  --colorstextlighttertiary: rgba(82, 82, 82, 1);
  --fill-colordarktertiary: rgba(118, 118, 128, 0.24);
  --fill-colorlighttertiary: rgba(118, 118, 128, 0.12);
  --footnote-bold-font-family: 'Inter-Bold', Helvetica;
  --footnote-bold-font-size: 14px;
  --footnote-bold-font-style: normal;
  --footnote-bold-font-weight: 700;
  --footnote-bold-letter-spacing: -0.011199999749660492px;
  --footnote-bold-line-height: 18px;
  --footnote-medium-font-family: 'Inter-Medium', Helvetica;
  --footnote-medium-font-size: 14px;
  --footnote-medium-font-style: normal;
  --footnote-medium-font-weight: 500;
  --footnote-medium-letter-spacing: -0.011199999749660492px;
  --footnote-medium-line-height: 18px;
  --footnote-regular-font-family: 'Inter-Regular', Helvetica;
  --footnote-regular-font-size: 14px;
  --footnote-regular-font-style: normal;
  --footnote-regular-font-weight: 400;
  --footnote-regular-letter-spacing: -0.011199999749660492px;
  --footnote-regular-line-height: 18px;
  --footnote-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --footnote-semibold-font-size: 14px;
  --footnote-semibold-font-style: normal;
  --footnote-semibold-font-weight: 600;
  --footnote-semibold-letter-spacing: -0.011199999749660492px;
  --footnote-semibold-line-height: 18px;
  --h2-bold-font-family: 'Inter-Bold', Helvetica;
  --h2-bold-font-size: 36px;
  --h2-bold-font-style: normal;
  --h2-bold-font-weight: 700;
  --h2-bold-letter-spacing: 0.13679999828338624px;
  --h2-bold-line-height: normal;
  --h2-h1-black-font-family: 'Inter-Black', Helvetica;
  --h2-h1-black-font-size: 48px;
  --h2-h1-black-font-style: normal;
  --h2-h1-black-font-weight: 900;
  --h2-h1-black-letter-spacing: 0px;
  --h2-h1-black-line-height: normal;
  --h2-h1-bold-font-family: 'SFPro Text-Bold', Helvetica;
  --h2-h1-bold-font-size: 48px;
  --h2-h1-bold-font-style: normal;
  --h2-h1-bold-font-weight: 700;
  --h2-h1-bold-letter-spacing: 0.19200000286102295px;
  --h2-h1-bold-line-height: normal;
  --h2-h1-medium-font-family: 'Inter-Medium', Helvetica;
  --h2-h1-medium-font-size: 48px;
  --h2-h1-medium-font-style: normal;
  --h2-h1-medium-font-weight: 500;
  --h2-h1-medium-letter-spacing: 0.19200000286102295px;
  --h2-h1-medium-line-height: normal;
  --h2-h1-regular-font-family: 'Inter-Regular', Helvetica;
  --h2-h1-regular-font-size: 48px;
  --h2-h1-regular-font-style: normal;
  --h2-h1-regular-font-weight: 400;
  --h2-h1-regular-letter-spacing: 0.19200000286102295px;
  --h2-h1-regular-line-height: normal;
  --h2-h1-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --h2-h1-semibold-font-size: 48px;
  --h2-h1-semibold-font-style: normal;
  --h2-h1-semibold-font-weight: 600;
  --h2-h1-semibold-letter-spacing: 0.19200000286102295px;
  --h2-h1-semibold-line-height: normal;
  --h2-medium-font-family: 'Inter-Medium', Helvetica;
  --h2-medium-font-size: 36px;
  --h2-medium-font-style: normal;
  --h2-medium-font-weight: 500;
  --h2-medium-letter-spacing: 0.13679999828338624px;
  --h2-medium-line-height: normal;
  --h2-regular-font-family: 'Inter-Regular', Helvetica;
  --h2-regular-font-size: 36px;
  --h2-regular-font-style: normal;
  --h2-regular-font-weight: 400;
  --h2-regular-letter-spacing: 0.13679999828338624px;
  --h2-regular-line-height: normal;
  --h2-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --h2-semibold-font-size: 36px;
  --h2-semibold-font-style: normal;
  --h2-semibold-font-weight: 600;
  --h2-semibold-letter-spacing: 0.13679999828338624px;
  --h2-semibold-line-height: normal;
  --h3-bold-font-family: 'Inter-Bold', Helvetica;
  --h3-bold-font-size: 24px;
  --h3-bold-font-style: normal;
  --h3-bold-font-weight: 700;
  --h3-bold-letter-spacing: -0.06239999771118164px;
  --h3-bold-line-height: normal;
  --h3-medium-font-family: 'Inter-Medium', Helvetica;
  --h3-medium-font-size: 24px;
  --h3-medium-font-style: normal;
  --h3-medium-font-weight: 500;
  --h3-medium-letter-spacing: -0.06239999771118164px;
  --h3-medium-line-height: normal;
  --h3-regular-font-family: 'Inter-Regular', Helvetica;
  --h3-regular-font-size: 24px;
  --h3-regular-font-style: normal;
  --h3-regular-font-weight: 400;
  --h3-regular-letter-spacing: -0.06239999771118164px;
  --h3-regular-line-height: normal;
  --h3-semibold-font-family: 'Inter-SemiBold', Helvetica;
  --h3-semibold-font-size: 24px;
  --h3-semibold-font-style: normal;
  --h3-semibold-font-weight: 600;
  --h3-semibold-letter-spacing: -0.06239999771118164px;
  --h3-semibold-line-height: normal;
  --label-colordarkprimary: rgba(255, 255, 255, 1);
  --label-colorlightprimary: rgba(0, 0, 0, 1);
  --separator-colordarkwith-transparency: rgba(84, 84, 88, 0.65);
  --separator-colorlightwith-transparency: rgba(60, 60, 67, 0.36);
  --system-backgroundlightprimary: rgba(255, 255, 255, 1);
  --systembgdarkprimary: rgba(0, 0, 0, 1);
  --systembgdarksecondary: rgba(255, 255, 255, 1);
  --systembgdarktertiary: rgba(28, 28, 30, 1);
  --systembglightprimary: rgba(0, 0, 0, 1);
  --systembglightsecondary: rgba(255, 255, 255, 1);
  --systembglighttertiary: rgba(28, 28, 30, 1);
  --white: rgba(255, 255, 255, 1);
  --bg-gradient-light: linear-gradient(87deg, #1DADFF 2%, #FF7043 117.19%);
  --bg-gradient-dark: linear-gradient(87deg, #80BAF8 2%, #F1A588 117.19%);
  --border-gradient-blue-deepOrange: linear-gradient(to right, #1DADFF, #FF7043);
  --border-gradient-blue-deepOrange-dark: linear-gradient(to right, #8DD0FF, #FFAB91);
}

:root {
  --text-primary: theme(colors.gray.950);
  --text-secondary: theme(colors.gray.600);
  --text-tertiary: theme(colors.gray.500);
  --text-quaternary: theme(colors.gray.300);
  --surface-primary: theme(colors.white);
  --surface-secondary: theme(colors.gray.50);
  --surface-tertiary: theme(colors.gray.100);
  --border-light: theme(colors.black / 10%);
  --border-medium: theme(colors.black / 15%);
  --border-heavy: theme(colors.black / 20%);
  --border-xheavy: theme(colors.black / 25%);
  --link: #2964aa;
  --link-hover: #749ac8;
  --tgpt-secondary-main:rgba(96, 125, 139, 1);
  --mui-success-main: #2E7D32;
  --success-dark :rgba(27, 94, 32, 1);
  --success-accent: rgba(200, 230, 201, 1);
  --error-dark: rgba(198, 40, 40, 1);
  --error-accent: rgba(254, 205, 210, 1);
  --text-error-main:rgba(244, 67, 54, 1);
  --text-success-main: rgba(76, 175, 80, 1);
  --error-states-focusVisible:rgba(211, 47, 47, 0.3);
  --tgpt-secondary-accentLight: rgba(207, 216, 220, 0.2);
  --tgpt-secondary-states-hover: rgba(96, 125, 139, 0.04);
  --tgpt-secondary-states-focus: rgba(96, 125, 139, 0.12);
  --warning-main-dark: rgba(239, 108, 0, 1);
  --components-alert-error: rgba(95, 33, 32, 1);
  --components-alert-error-background: rgba(253, 237, 237, 1);
}

.dark {
  --text-primary: theme(colors.gray.100);
  --text-secondary: theme(colors.gray.300);
  --text-tertiary: theme(colors.gray.500);
  --text-quaternary: theme(colors.gray.700);
  --surface-primary: theme(colors.gray.900);
  --surface-secondary: theme(colors.gray.800);
  --surface-tertiary: theme(colors.gray.700);
  --border-light: theme(colors.gray.200 / 10%);
  --border-medium: theme(colors.gray.200 / 15%);
  --border-heavy: theme(colors.gray.200 / 20%);
  --border-xheavy: theme(colors.gray.200 / 25%);
  --link: #7ab7ff;
  --link-hover: #5e83b3;
  --tgpt-secondary-main: rgba(144, 164, 174, 1);
  --mui-success-main: #66BB6A;
  --success-dark :rgba(56, 142, 60, 1);
  --success-accent: rgba(232, 245, 233, 1);
  --error-dark: rgba(211, 47, 47, 1);
  --error-accent: rgba(254, 235, 238, 1);
  --text-error-main: rgba(239, 154, 154, 1);
  --text-success-main: rgba(165, 214, 167, 1);
  --error-states-focusVisible:rgba(244, 67, 54, 0.3);
  --tgpt-secondary-accentLight: rgba(38, 50, 56, 0.2);
  --tgpt-secondary-states-hover: rgba(176, 190, 197, 0.08);
  --warning-main-dark: rgba(255, 167, 38, 1);
  --tgpt-secondary-states-focus: rgba(176, 190, 197, 0.12);
  --components-alert-error:rgba(244, 199, 199, 1);
  --components-alert-error-background: rgba(22, 11, 11, 1);
}

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

#root {
  @apply flex;
}

@layer components {
  .btn {
    @apply inline-flex items-center rounded-lg border border-transparent text-sm font-medium px-3 py-2;
    &-small {
      @apply px-2 py-1;
    }
  }

  .btn-neutral {
    @apply text-sm bg-token-surface-primary text-token-text-primary border border-token-border-light;
    // border-color: rgba(0,0,0,.1);
    &:hover {
      @apply bg-gray-50;
    }
    &:focus {
      @apply ring-[rgba(69,89,164,.5)] ring-2 ring-offset-white ring-offset-2;
    }
    .dark & {
      @apply bg-token-surface-secondary hover:bg-gray-700 border-gray-600;
    }
  }
}

///////

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

html::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}

/* Scrollbar track - always transparent */
html::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Scrollbar thumb - transparent by default, styled on hover */
html::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 16px;
  border: 5px solid transparent;
}

/* Scrollbar thumb - Styled when hovered */
html:hover::-webkit-scrollbar-thumb {
  background-color: rgb(226, 226, 226);
  /* Change to the color you desire */
  border-radius: 16px;
  border: 5px solid #ffffff;
  /* Change to match the hover styling */
}

/* Scrollbar buttons (if any) - not displayed */
html::-webkit-scrollbar-button {
  display: none;
}
.barz {
  .highcharts-stocktools-wrapper {
    display: none;
  }
}
.strikethrough {
  text-decoration: line-through;
}

.save-text {
  color: var(--colors-text-light-tertiary, #525252);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.069px;
  white-space: nowrap;
}
.toggle-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
}
.bottom-bordered::after {
  content: '';
  display: block;
  height: 1px;
  background-color: #d2d5daa1;
  width: calc(100% - 29px);
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.top-bordered::before {
  margin-top: 0px;
  content: '';
  display: block;
  height: 1px;
  background-color: #d2d5daa1;
  width: calc(100% - 29px);
  margin-left: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.explore-icon {
  width: 24px !important;
}

.animated-tag {
  min-height: 265px;
  max-height: 265px;
}

.custom-list-item {
  padding-left: 6px !important;
  margin-left: 10px !important;
  margin-right: 18px !important;
  height: 36px !important;
  margin-top: 8px !important;
  width: 100%;

  &:hover {
    background-color: #2e2e32 !important; // Your desired hover color
    border-radius: 0.5rem !important;
  }

  .MuiListItemIcon-root {
    min-width: 38px !important;
  }
}

.primary-prev-chat {
  color: var(--colors-text-light-secondary, #fff) !important;
  font-size: 12.5px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: -0.011px !important;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  /* Adjust the width as needed */
  text-overflow: ellipsis;
  /* Adds an ellipsis (...) to the text that overflows */
}

.cursor-icon {
  display: inline-block;
  vertical-align: middle;
}

.scroll-container::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}

/* Scrollbar track - always transparent */
.scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Scrollbar thumb - transparent by default, styled on hover */
.scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 16px;
  border: 5px solid transparent;
}

.sidebar .scroll-container:hover::-webkit-scrollbar-thumb {
  background-color: rgb(29, 29, 29);
  border-radius: 16px;
  border: 5px solid black !important;
}

.chatmessagescrollbar .scroll-container:hover::-webkit-scrollbar-thumb {
  background-color: #343541a9;
  border-radius: 16px;
  border: 5px solid white !important;
}

.watchlist-scroller::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.watchlist-scroller::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 8px;
}
.watchlist-scroller:hover::-webkit-scrollbar-thumb {
  background-color:  #343541a9;
  border-radius: 8px;
}

/* Scrollbar track - always transparent */
.MuiPaper-root::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Scrollbar thumb - transparent by default, styled on hover */
.MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 16px;
  border: 5px solid transparent;
}

/* Scrollbar thumb - Styled when hovered */
.MuiPaper-root:hover::-webkit-scrollbar-thumb {
  background-color: rgb(104, 104, 104);
  /* Change to the color you desire */
  border-radius: 16px;
  border: 5px solid #000;
  /* Change to match the hover styling */
}

/* Scrollbar buttons (if any) - not displayed */
.MuiPaper-root::-webkit-scrollbar-button {
  display: none;
}

.translator-a,
.translator-b {
  transition: transform 0.45s ease;
}

.tradegpt-input-container {
  .MuiOutlinedInput-root:hover {
    border-color: transparent !important;
  }
}

.left-sidebar-carrot,
.right-sidebar-carrot {
  opacity: 0.4;
  transform: translateX(260px) translateY(-50%) rotate(0deg) translateZ(0px);

  .translator-a {
    // opacity: 0.8;
    transform: translateY(0.15rem) rotate(0deg) translateZ(0px);
  }

  .translator-b {
    // opacity: 0.8;
    transform: translateY(-0.15rem) rotate(0deg) translateZ(0px);
  }
}
.right-sidebar-carrot {
  transform: translateX(-360px) translateY(-50%) rotate(0deg) translateZ(0px);
  &.closed {
    transform: translateX(0) translateY(-50%) rotate(0deg) translateZ(0px);
  }
}

.left-sidebar-carrot.closed:hover,
.right-sidebar-carrot:hover {
  .translator-a {
    transform: translateY(0.15rem) rotate(-15deg) translateZ(0px);
  }
  .translator-b {
    transform: translateY(-0.15rem) rotate(15deg) translateZ(0px);
  }
}

.left-sidebar-carrot:hover,
.right-sidebar-carrot.closed:hover {
  opacity: 1;

  .translator-a {
    // opacity: 1;
    transform: translateY(0.15rem) rotate(15deg) translateZ(0px);
  }

  .translator-b {
    // opacity: 1;
    transform: translateY(-0.15rem) rotate(-15deg) translateZ(0px);
  }
}

.sidebar-logo {
  // Style your logo if needed, for example:
  width: 28px; // Adjust as needed
  height: auto;
  margin-right: 10px; // Spacing between logo and title
}

.close-side {
  position: absolute;
  border: 0px;
  width: 1px;
  height: 1px;
  padding: 0px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  overflow-wrap: normal;
}
.highcharts-indicators {
  display: none;
}
.button-container {
  button {
    display: flex;
    justify-content: flex-start !important;
    border-color: #868585 !important;
    color: #000000;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 187.5% */
    letter-spacing: -0.32px;
    text-transform: none;
  }
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px; // Adjust as needed
  background-color: #1c1c1e !important; // Match the sidebar background or choose another color

  .sidebar-title {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; // 150%
  }
}

.MuiListItem-button:hover {
  background-color: rgba(255, 255, 255, 0.1); // Slight highlight on hover
}

.bottom-divider {
  color: white;
  background: rgba(84, 84, 88, 0.65);
}

.sidebar-bottom {
  position: fixed;
  bottom: 0;
  padding: 10px !important;
  width: 250px !important; // match the width of your sidebar
  background-color: #000 !important; // or the color of your sidebar
  z-index: 10 !important; // ensure it's above other elements

  .MuiListItem-root {
    padding: 10px !important;
    padding-top: 20px !important;
  }
}

.custom-style {
  line-height: 18px;
  letter-spacing: -0.011px;
}

.MuiDrawer-paper {
  overflow-x: hidden !important;
  background-color: #000 !important; // Set the background color for light mode
  width: 250px !important;
  box-sizing: border-box !important;
}

.new-chat-button {
  width: 221px !important;
  margin: 10px !important;
  margin-top: 20px !important;
  border-radius: 8px !important;
  border: 1px solid rgba(84, 84, 88, 0.65) !important; // Using a solid color as fallback
  display: flex !important;
  justify-content: space-between !important; // Align text and icon on opposite ends
  align-items: center !important;
  height: 44px !important;
  padding: 13px !important;
  padding-right: 20px;
  background-color: transparent !important; // Background color for light mode
  color: white !important;
  text-transform: none !important;
  background: #232323bd !important;

  .MuiButton-endIcon {
    margin-left: auto; // Push the icon to the right
  }
}

.my-plan-upgrade {
  border-radius: 6px;
  background: #f7e29f;
  color: #2e2e32;
  display: flex;
  justify-content: center;
  text-align: center;
  // font-family: SF Pro Text, "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

// .menu-paper {
//   color: white !important;
//   background-color: rgb(38, 38, 38) !important;
//   left: 16px !important;

//   border-radius: 12px !important;
//   font-family: Inter !important;
//   ul {
//     display: flex !important;
//     flex-direction: column !important;
//     font-family: Inter !important;
//   }
// }

.nested-menu-paper {
  li {
    font-size: 14px !important;
    color: #ececec;
    padding: 8px 12px;
    font-weight: 400;
    line-height: 18px;
  }

  left: 227px !important;
  color: white !important;
  background-color: rgb(38, 38, 38) !important;
  width: 100% !important;
  max-width: 190px !important;
  border-radius: 12px !important;
  margin-left: 25px !important;
}

// .submenu-item {
//   width: 228px;
//   max-height: 48px;
//   padding: 10px 16px !important;
//   display: flex !important;
//   justify-content: space-between !important;
//   line-height: 24px;
//   font-size: 16px !important;

//   .MuiTouchRipple-root {
//     padding: 10px;
//   }
// }

.portfolio-connect-section {
  text-align: center;
  margin-top: 9%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 19px;
}

.portfolio-logo {
  width: 60px;
}

.portfolio-connect-text {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 403px;
  color: var(--colors-text-light-tertiary, #525252);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.062px;
}

.connect-portfolio-button {
  display: flex !important;
  padding: 8px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 4px !important;
  border-radius: 6px !important;
  border: 1px solid var(--colors-fill-light-gray-2, #e5e5e5) !important;
  text-transform: none !important;
  text-align: center !important;
  width: 171px !important;
  height: 38px !important;
  color: var(--colors-text-light-primary, #1c1c1e) !important;
  text-align: center;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  margin-top: 20px !important;
  /* 137.5% */
  letter-spacing: -0.069px !important;
}

.connect-icon {
  // Add styles for the connect icon if needed
  width: 24px;
  margin-right: 10px;
}

// Top Bar Styles
.upgrade-icon {
  width: 21px;
  position: relative;
  left: -10px;
  bottom: 0px;
}

.top-bar {
  display: flex;
  align-items: center;
  padding: 9px;
  // border-bottom: 1px solid var(--colors-separator-light, #e4e5e8);
  gap: 18px;
}

.explore-button {
  display: flex !important;
  justify-content: flex-start !important;
  gap: 15px !important;
  border: none !important;
  background-color: #000 !important;
}

.top-bar-title {
  color: var(--colors-text-light-primary, #1c1c1e) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  letter-spacing: -0.09px !important;
}

.upgrade-plan-button {
  margin-bottom: 3px !important;
  height: 34px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: -0.069px !important;
  color: var(--colors-text-light-primary, #1c1c1e) !important;
  text-transform: none !important;
  border-radius: 6px !important;
  border: 1px solid var(--colors-fill-light-gray-2, #e5e5e5) !important;
  padding: 2px !important;
}

.search-icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: rgba(163, 163, 163, 1);
}

.search-paper {
  padding: 2px 4px;

  .MuiInputBase-input {
    color: white !important;
  }

  display: flex;
  align-items: center;
  width: 220px;
  height: 40px;
  margin-left: 11px;
  margin-top: 14px !important;
  margin-bottom: 14px !important;
  border-radius: 8px !important;
  border: 1px solid var(--neutrals-gray-20, rgba(84, 84, 88, 0.65));
  background: rgba(120, 120, 128, 0.2) !important;
  color: white;
}

.new-chat-icon {
  width: 20px;
  margin-right: 8px;
}

.account-circle {
  width: 28px;
}

.new-chat-button {
  border: none !important;
  border-radius: 6px;
  color: var(--colors-text-light-secondary, #fff);
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: -0.069px !important;
}

.linear-progress {
  width: 90%;
  margin-left: 47px;
  margin-top: 55px;
}

.send-icon {
  width: 38px;
  background-color: white;
}

.example-prompts {
  color: var(--colors-text-light-primary, #1c1c1e);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 133.333% */
  letter-spacing: -0.077px;
}

.prompt-title {
  color: var(--colors-text-light-primary, #1c1c1e);
  /* Footnote/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: -0.011px;
}

.final-completion > :last-child {
  padding-bottom: 20px;
}

.bullet-gpt {
  color: var(--colors-text-light-quaternary, #a3a3a3);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: -0.011px;
}

.gpt-image {
  width: 42px;
  height: 42px;
}

.chat-actions {
  width: 24px;
  height: 24px;
}

.primary-title {
  color: var(--colors-text-light-gray-4, #525252) !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
  max-width: 850px !important;
  padding-right: 15px;
}

.secondary-title {
  color: var(--colors-text-light-gray-4, #525252);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 850px !important;
  padding-right: 15px;
}

.suggestion-box {
  padding: 40px;
  padding-bottom: 30px;
}

.tradegpt-input-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 0 20px;

  .MuiFormControl-root {
    display: flex !important;
    align-items: center;
  }

  .MuiInputBase-root {
    border-radius: 20px !important;
    border: 1px solid var(--colors-fill-light-gray-4, #525252) !important;
    background: var(--neutrals-white, #fff) !important;
  }

  .Mui-focused {
    border-color: transparent !important;
  }

  /* Adjust padding as needed */
  background: white;
  /* Or any color that matches your design */
  z-index: 1000;
  /* Ensure it's above other elements within chat-content */
}

/* Example if sidebar is on the left with a width of 250px */
.tradegpt-input-container {
  right: 0;
}

.tradegpt-container {
  display: flex;
  flex-wrap: nowrap !important;
  max-width: fit-content !important;
  flex-basis: auto !important;

  .toggle-drawer-button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1200;
  }
}

.top-right-trade {
  height: 28px;
  width: 28px;
  margin-bottom: 20px;
  margin-right: 15px;
}

.custom-height {
  max-height: calc(100vh - 97px);
}

.theme-text {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.011px;
}

.delete-chats {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.011px;
}

.MuiListItemIcon-root,
.MuiListItemText-primary,
.MuiListItemText-secondary {
  color: white; // Adjust the text color for better visibility
}

.chat-bubble {
  fill: white; // Change fill color for better visibility
  width: 26px;
  margin-left: 11px;
  margin-bottom: 11px;
}

// .MuiListItemText-root::after {
//   content: '';
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 100px; /* Width of the fade effect */
//   height: 100%;
//   background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(0, 0, 0)); /* Adjust the color to match your background */
//   pointer-events: none;
// }

.list-title {
  color: var(--colors-text-light-quaternary, #a3a3a3);

  /* Caption 1/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.secondary-prev-chat {
  color: var(--colors-text-light-gray-3, #a3a3a3) !important;

  /* Caption 1/Regular */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  /* 133.333% */
}

.chat-content {
  flex-grow: 1;
  padding: 10px;
  min-width: 85vw !important;
}

.chat-window-container {
  display: flex;
  flex-direction: column;
  height: 100%; // Adjust based on your layout
  padding-bottom: 60px;

  .MuiList-root {
    padding-bottom: 30px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.chat-window-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .MessageInput-class {
    // Replace with your actual class name for MessageInput
    margin-top: auto; // Pushes the input to the bottom
  }
}

.first-paper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-x: hidden;
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;
  background: #fbfaff;
}

.inner-box {
  height: 100%;
  overflow: auto;
  padding: 36px;
  background-color: transparent;
}

.prompts-paper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  cursor: pointer;
  border-radius: 16px !important;
  border: 1px solid #d1d5db !important;
  background: var(--colors-fill-light-quaternary, #fff) !important;
  height: 80px !important;
}

.prompts-button {
  flex-grow: 1;
  text-align: left;
  justify-content: flex-start;
}

.bullet-ul {
  padding-top: 10px;
  width: 100%;
  padding-left: 20px;
}

.bottom-input {
  background-color: white;
}

.bot-actions {
  .MuiSvgIcon-root {
    width: 18px;
  }
}

#highcharts-container {
  width: 100%;
}

.list-wrap {
  border-radius: 9px;
}

.markdown-body * {
  background-color: white !important;
  color: #000000DE;
  font-size: 14px;
}

.markdown-body {
  background-color: white !important;
  color: #000000DE;

  &.change-log-markdown {
    ul  {
      @apply list-disc pl-4;
    }
  }
}

.MuiDataGrid-menuList {
  .MuiTypography-root {
    color: black !important;
  }
}

.submenu-divider {
  background: rgb(83, 82, 82) !important;
}

.right-arrow {
  color: white;
}

.refer-icon {
  width: 24px;
  fill: white;
}

.svg-left {
  path {
    fill: #a3a3a3;
  }
}

.stop-button {
  background-color: black;
  border-radius: 20%;
  padding: 6px;
  margin-right: 10px;
}

.faq-logo {
  width: 36px;
  height: 36px;
}

.faq-footer-logo {
  width: 36px;
  height: 36px;
}

.search-text {
  color: white !important;
}

.input-focus:focus,
.textarea-focus:focus {
  border: 1px solid #007bff;
  outline: none;
}

.sidebar {
  background-color: black;
}

.sidebar-input:focus-visible {
  outline: none;
}

.sidebar-input {
  color: black !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  font-size: 16px;
}
.MuiDataGrid-selectedRowCount {
  color: gray;
}

.tradegpt-search-field-wrapper {
  border-radius: 8px;
  border: 1px solid rgba(84, 84, 88, 0.65);
  background: #1c1c1e;
  position: relative;
  margin-top: 14px;
  padding: 8px 34px 8px 8px;
  position: relative;
}

.tradegpt-search-bar {
  background: transparent;
  color: #e5e5e5;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.011px;
  width: 100%;
}

.tradegpt-search-bar:focus {
  outline: none;
}

.tradegpt-search-icon {
  width: 18px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.tradegpt-dashboard-menu-item {
  margin-top: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.011px;
  cursor: pointer;
}

.tradegpt-dashboard-menu-item:hover {
  background-color: var(--surface-primary);
  border-radius: 10px;
}

.tradegpt-dashboard-menu-icon {
  width: 28px;
}

.tradegpt-dashboard-component {
  width: calc(100% - 20px);
  max-width: 750px;
  margin: 0 auto;
  margin-top: 80px;
}

.tradegpt-dashboard-header {
  color: #1c1c1e;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.182px;
  margin-bottom: 20px;
}

.tradegpt-dashboard-subheader {
  color: #525252;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.077px;
  margin-bottom: 40px;
}

.tradegpt-dashboard-banner {
  border-radius: 12px;
  background: #f7f7f8;
  position: relative;
  margin-bottom: 40px;
  overflow: auto;
}

.tradegpt-dashboard-banner-content {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 80%;
  max-width: 500px;
}

.tradegpt-dashboard-banner-logo {
  height: 24px;
}

.tradegpt-dashboard-banner-text {
  color: #1c1c1e;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.077px;
}

.tradegpt-dashboard-banner-img {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}

.tradegpt-dashboard-CTA-button {
  border-radius: 6px !important;
  background: #007bff !important;
  padding: 14px 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 4px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  letter-spacing: -0.011px !important;
  text-transform: capitalize !important;
}

.tradegpt-dashboard-features {
  margin-bottom: 144px;
}

.tradegpt-dashboard-features-header {
  color: #1c1c1e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.011px;
  margin-bottom: 16px;
}

.tradegpt-dashboard-features-content {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
}

.tradegpt-dashboard-feature {
  width: calc(50% - 8px);
  border-radius: 12px;
  padding: 16px 24px;
}

.tradegpt-dashboard-feature-icon {
  height: 24px;
  display: flex;
  align-items: center;
}

.tradegpt-dashboard-feature-title {
  color: #1c1c1e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.011px;
  margin: 10px 0 4px 0;
}

.tradegpt-dashboard-feature-description {
  color: #525252;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.tradegpt-dashboard-footer {
  text-align: center;
}

.tradegpt-dashboard-footer-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.09px;
}

.tradegpt-dashboard-footer-logo {
  width: 36px;
}

.tradegpt-dashboard-footer-links {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tradegpt-dashboard-footer-link {
  color: #a3a3a3;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.011px;
  padding: 0 16px;
  border-right: 1px solid #1c1c1e;
  cursor: pointer;
}

.tradegpt-dashboard-footer-link:last-child {
  border-right: none;
}

@media (max-width: 768px) {
  .tradegpt-dashboard-banner-content {
    width: 100%;
    max-width: initial;
    align-items: center;
  }

  .tradegpt-dashboard-banner-text {
    text-align: center;
  }

  .tradegpt-dashboard-banner-img {
    display: none;
  }

  .tradegpt-dashboard-features-content {
    gap: 0;
  }

  .tradegpt-dashboard-feature {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tradegpt-dashboard-features-header {
    text-align: center;
  }

  .tradegpt-dashboard-feature-description {
    text-align: center;
  }
}

.shadow-custom {
  box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20);
}

.bg-gradient-animated {
  align-items: end;
  background-image: var(--bg-gradient-light);
  background-size: 300% 300%;
  animation: gradient-animation 6s ease-in-out infinite;
  position:relative;
}

.dark .bg-gradient-animated {
  background-image: var(--bg-gradient-dark);
}

@keyframes gradient-animation {
  0%, 100% {
    background-position: 50% 50%; /* Center */
  }
  20% {
    background-position: 0% 0%; /* Top Left */
  }
  40% {
    background-position: 100% 0%; /* Top Right */
  }
  60% {
    background-position: 100% 100%; /* Bottom Right */
  }
  80% {
    background-position: 0% 100%; /* Bottom Left */
  }
}

.white-mask {
  top: -1px;
  left: -1px;
  position: absolute;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  mask-image: var(--mask-url);
  -webkit-mask-image: var(--mask-url); /* Safari support */
  background: white;
}

.gradient-border-box {
  position: relative;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
}

.gradient-border-box::after {
  content: '';
  position: absolute;
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--border-gradient-blue-deepOrange);
  z-index: -1;
  padding: 1px; 
  border-radius: 8px;
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  opacity: 0;
}

.dark .gradient-border-box::after {
  background: var(--border-gradient-blue-deepOrange-dark)
}
.gradient-border-box:hover::after,
.gradient-border-box.selected::after {
  opacity: 1;
}

.sparkline-tooltip-content {
  text-align: center;
  font-weight: 500;
  font-family: "Inter";
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px !important;
  margin-left: 8px !important ;
  margin-top: 4px !important;
  margin-bottom:4px !important;
}

.sparkline-tooltip-price {
  color: white;
  font-size: 12px;
  margin-bottom: 8px;
}

.sparkline-tooltip-time {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;  
}