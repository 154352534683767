body {
  &.dark {

    @apply bg-token-surface-secondary;

    .border-zinc-200 {
      border-color: #6c6c6c
    }

    .CompareTslaWithTheSP500 {
      color: white;
    }

    .ModalMyPlanFreeTier {
      background-color: rgb(32, 33, 35)
    }

    .ModalShareAnoymously {
      background-color: rgb(32, 33, 35)
    }

    .MessagesYouSendAfterCreatingYourLinkWonTBeSharedAnyoneWithTheUrlWillBeAbleToViewTheSharedChat {
      color: whitesmoke;
    }

    .Content-shared {
      background-color: #343540;
    }

    .Share-Text {
      color: white;
    }

    .highcharts-label {
      text {
        fill: black !important;
      }
    }

    .markdown-body a {
      background-color: transparent;
      color: var(--color-accent-fg);
      text-decoration: none;
    }

    .chatmessagescrollbar .scroll-container:hover::-webkit-scrollbar-thumb {
      background-color: black;
      border-radius: 16px;
      border: 5px solid rgba(30, 30, 30, 1) !important;
    }

    .watchlist-scroller::-webkit-scrollbar-thumb {
      background-color: rgba(18,18,18,1);
      border-radius: 16px;
    }
    .MuiDataGrid-main * {

      color: white;
    }

    .MuiDataGrid-menu {
      .MuiDataGrid-menuList {
        background: #52515b !important
      }
    }

    .MuiToolbar-root * {
      color: white;
    }

    .translator-a {
      background-color: white;
    }

    .translator-b {
      background-color: white;
    }

    .highcharts-background {
      fill: #343540;
    }

    .highcharts-axis-title {
      fill: white !important;
    }

    // .svg-left {
    //   path {
    //     fill: #C5C5D1;
    //   }
    // }

    // .plans {
    //   color: white
    // }

    // .sub-back-button {
    //   color: #C5C5D1;
    // }

    // .sub-info {
    //   color: #C5C5D1;
    // }

    // .sub-title {
    //   color: white;
    // }

    // .sub-color {
    //   background: black;
    // }

    .markdown-body {
      background: transparent !important;
    }

    .list-wrap.relative {
      background: transparent !important;
    }

    .tradegpt-input-container {
      background: #343540 !important;

      .MuiInputBase-root {
        background: rgba(52, 53, 64, 0.95) !important;
        border-color: #8E8EA0 !important
      }
    }

    .prompt-title {
      color: #C5C5D1 !important;
    }

    .bullet-gpt {
      color: #7C7C88 !important;
    }

    .prompts-grid {
      .MuiPaper-root {
        background: #343540 !important;
      }
    }

    .bottom-text {
      color: #7C7C88;
    }

    .sidebar-input{
      color: white !important
    }

    .upgrade-plan-button {
      color: white !important;
    }

    .top-bar-title {
      color: white !important;
    }

    .how-can {
      color: #C5C5D1;
    }

    .bot-actions {
      .MuiSvgIcon-root {
        color: white !important;
      }
    }

    .dark-skeleton {
      background-color: rgba(255,
          255,
          255,
          0.11);
      /* Slightly visible on dark background */
      border-radius: 4px;
      /* Smooth rounded corners */
      /* Optional: add a subtle animation for a pulsing effect */
      animation: pulse 1.5s ease-in-out infinite;

      @keyframes pulse {
        0% {
          background-color: rgba(255, 255, 255, 0.08);
        }

        50% {
          background-color: rgba(255, 255, 255, 0.13);
        }

        100% {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }
    }

    .connect-portfolio-button {
      color: white !important;
    }

    .example-prompts {
      color: white;
    }

    .prompt-title {
      color: white;
    }

    .MuiListItemText-primary {
      color: white;
    }

    .MuiListItemText-secondary {
      color: white;
    }

    .MuiDivider-fullWidth {
      border-bottom-color: #494949;
    }

    .chat-bubble {
      fill: white;
    }

    .prev-chat-list {
      background: #060606 !important;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4),
        0 6px 20px 0 rgba(0, 0, 0, 0.35);
      /* Darker, more subtle shadow */
      border-radius: 10px;
      /* Optional: Rounded corners for a modern look */
      padding: 20px;
      /* Optional: Padding for content inside the component */
      transition: box-shadow 0.3s ease;
    }

    .prev-chat-list-item {
      border-bottom: 1px solid white;
    }

    .prev-chat-list-item:hover {
      cursor: pointer !important;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5),
        0 12px 40px 0 rgba(0, 0, 0, 0.45);
      /* Slightly more pronounced shadow on hover */
    }

    .MuiDataGrid-menuList {
      .MuiTypography-root {
        color: white !important;
      }
    }

    .highcharts-label text {
      fill: white !important;
    }

    .highcharts-axis-labels text {
      fill: white !important;
    }

    .highcharts-text-outline {
      stroke: black;
      stroke-width: 2px;
    }

    .highcharts-label.highcharts-range-input text {
      fill: white !important;
      text-decoration: underline;
    }

    .highcharts-button-disabled {
      opacity: 0.4;
    }

    .highcharts-background {
      fill: transparent;
    }

    .highcharts-title {
      fill: white !important;
    }

    .highcharts-button-box {
      fill: #7C7C88;
    }

    .markdown-body {
      background-color: rgba(30, 30, 30, 1) !important;
      color: white;

      &.change-log-markdown {
        background-color: inherit !important;
      }
    }

    .markdown-body * {
      background-color: rgba(30, 30, 30, 1) !important;
      color: white;
    }

    .markdown-body.change-log-markdown * {
      background-color: inherit !important;
    }

    .list-wrap {
      background-color: #343540 !important;
    }

    .person-icon {
      color: white !important;
    }

    .smart-toy {
      color: white !important;
    }

    .send-icon {
      fill: white !important;
    }

    .trade-search-icon {
      fill: white;
    }

    .send-icon {
      background-color: transparent !important;
    }

    .bottom-input {
      background-color: transparent !important;

      .MuiOutlinedInput-root {
        // Style the MuiOutlinedInput-root
        color: white;

        .MuiOutlinedInput-notchedOutline {
          border-color: white !important; // Custom border color for dark mode
        }
      }
    }

    .sidebar .MuiDrawer-paper .MuiDrawer-paper .MuiMenu-list {
      background: #060606 !important;
    }

    .sidebar {
      .chat-bubble {
        color: white;
      }

      .MuiMenu-list {
        background: #060606 !important;
      }

      .MuiDrawer-paper {
        background: #060606;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4),
          0 6px 20px 0 rgba(0, 0, 0, 0.35);

        .MuiListItem-root {
          border-bottom: 1px solid white;

          &:hover {
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5),
              0 12px 40px 0 rgba(0, 0, 0, 0.45);
          }
        }
      }
    }

    .bullet-gpt {
      color: white;
    }

    .prompts-paper {
      background: #343540 !important;
    }

    .first-paper {
      background: transparent !important;
    }

    .primary-title {
      color: white !important;
    }

    .tradegpt-dashboard-header {
      color: #fff;
    }

    .tradegpt-dashboard-subheader {
      color: #C5C5D1;
    }

    .tradegpt-dashboard-banner {
      background: #202123;
    }

    .tradegpt-dashboard-banner-text {
      color: #fff;
    }

    .tradegpt-dashboard-features-header {
      color: #fff;
    }

    .tradegpt-dashboard-feature-title {
      color: #fff;
    }

    .tradegpt-dashboard-feature-description {
      color: #C5C5D1;
    }

    .tradegpt-dashboard-footer-header {
      color: #fff;
    }

    .tradegpt-dashboard-footer-link {
      color: #C5C5D1;
      border-color: #fff;
    }
  }

  // Targeting the Highcharts stock tools toolbar container
  .highcharts-container .highcharts-stocktools-toolbar {
    background: #333; // Adjust the background color to match your dark theme

    button {
      fill: #666; // Adjust icon fill for default state
      stroke: #666; // Adjust icon stroke if necessary
      &:hover {
        fill: #aaa; // Adjust for hover state
        stroke: #aaa; // Adjust stroke on hover if necessary
      }
    }
    // Adjusting the background color for button groups on hover
    .highcharts-button:hover {
      background-color: #555; // Darker than the toolbar background for contrast
    }
  }
}

.sparkline-tooltip-price {
  color: white;
}

.sparkline-tooltip-time {
  color: rgba(255, 255, 255, 0.6);
}
